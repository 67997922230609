@import url("https://fonts.googleapis.com/css?family=Poppins");
.Previous-Bootcamp {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
.previousbootcamp-section {
  display: flex;
  width: 100%;
}
.detailcourses-banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 490px;
  margin: auto;
  border-radius: 20px;
  /* background-image: url("../../assets/detail-course.jpg"); */
  
  background-repeat: no-repeat;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
}
.detailcourses-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px;
}
.foot-banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  margin: auto;
  border-radius: 15px;
  background: rgba(131, 52, 113, 0);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(16px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
}
.footbanner-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px;
}
.detailcourses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  /* border-radius: 20px; */
  padding-bottom: 10px;
  position: relative;
}
.bootcamp-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: flex-start;
  justify-content: center;
}
.wrapper-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
}
.wrapper-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
}
/* Training program */
.training-program {
  margin: 40px auto 20px auto;
}
.program-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: #833471;
  margin: 20px auto;
}
.program-goal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.4);
}
.costing-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 600px) {
  .training-program {
    margin: auto;
    width: 100%;
  }
  .program-heading {
    margin: 10px auto;
  }
  .detailcourses-banner {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  .foot-banner {
    display: none;
  }
  .bootcamp-wrapper {
    display: flex;
    flex-direction: column !important;
    width: 90%;
  }
  .detailcourses-container {
    margin-top: 20px;
  }
}
/* Costing Card*/
.program-cost {
  display: flex;
  flex-direction: column;
  width: 33%;
  text-align: center;
}
.head1 {
  margin: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #833471;
  display: flex;
}
.head2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.3);
}
.program-enroll {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 33%; */
}
.enrollBtn {
  width: 202px;
  height: 67px;
  background-color: #833471;
  box-shadow: 0px 6px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
.syllabus-download {
  display: flex;
  align-items: center;
  justify-content: center;
}
.downloadBtn {
  width: 285px;
  height: 67px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #833471;
  background: #ffffff;
  border: 1px solid #833471;
  border-radius: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .costing-card {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .program-cost {
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .program-enroll {
    width: 100%;
    margin: 10px auto;
  }
  .enrollBtn {
    width: 285px;
    height: 67px;
  }
  .syllabus-download {
    width: 100%;
    margin: 10px auto;
  }
  .sidebar-bottom{
    flex-direction: column!important;
    height: fit-content!important;
  }
  .details-listing{
    margin: 10px;
  }
  .sidebar-detail{
    height: 400px;
    grid-template-columns: 100%!important;
  }
  .sidebar-header{
    width: 90%!important;
  }
  .sidebar{
    height: fit-content!important;
  }
  .vertical-details-listing{
    grid-template-columns: 100%;
  }
  .vertical-bar{
    flex-direction: column!important;
  }
  .sidebar-detail-alt{
    width: 100%!important;
  }
  .vertical-header{
    flex-direction: row!important;
    justify-content: space-around!important;
    margin: 20px auto !important;
  }
}
/* Benefit card */
.benefitCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 325px;
  background-color: #f5f5f5;
  margin: 20px 10px;
}
.card-benefit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.benefit-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.46);
  margin: 5px auto;
  width: 96%;
}
.benefit-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
}
.instructor-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px auto;
  background-color: #f6f7ff;
}
.section-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #833471;
  margin: 20px 0px;
}
.flex-start {
  justify-content: flex-start;
}
.section-heading-ext {
  justify-content: flex-start !important;
  margin: 50px 30px;
}
.card-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
}
.card-section::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 600px) {
  .benefit-card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .benefitCard {
    width: 100%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-self: center;
  }
}
/* Instructor Card */
.instructorCard {
  min-width: 250px;
  height: 333px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.instructorImg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.instructorName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #833471;
}
.instructorExp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.46);
}
.instructorCompany {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.carousel-logo {
  margin: 5px;
  width: 50px;
}
.tech-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px auto;
}
.tech-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #833471;
  width: 100%;
  text-align: center;
}
.tech-logo {
  margin: 20px;
  width: 90px;
  height: 90px;
}
.techcard-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.techcard-section::-webkit-scrollbar {
  display: none;
}
.certificate-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 40px auto 40px auto;
}
.certificate-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 30px;
}
.certificate-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 96px;
  color: #833471;
  width: 100%;
}
.certificate-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.46);
  width: 90%;
}
.certificate-img {
  width: 50%;
  box-shadow: 10px 10px 30px 20px #f2f2f2;
  margin-left: 20px;
}
.certificate {
  width: 100%;
  height: 482px;
}
@media only screen and (max-width: 600px) {
  .techcard-section {
    justify-content: initial;
  }
  .card-section::-webkit-scrollbar,
  .techcard-section::-webkit-scrollbar {
    display: block;
  }
  .card-section {
    justify-content: initial;
  }
  .certificate-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    width: 100%;
  }
  .certificate-img {
    width: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px !important;
  }
  .certificate {
    width: 100% !important;
    height: 100% !important;
  }
  .certificate-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 30px !important;
  }
  .certificate-name {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
  }
  .certificate-name br {
    display: none;
  }
  .certificate-info {
    margin: 10px 0px;
  }
}
/* Sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 500px;
  margin: auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 0.25px solid #6f1e51;
  background-color: #f2f2f2 !important;
}
.sidebar-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  font-size: 30px;
  font-weight: 900;
  color: #6f1e51;
  justify-content: space-between;
  align-items: center;
}
.sidebar-header a {
  margin: 0px;
}
.sidebar-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 400px;
  margin: auto;
}
.sidebar-detail {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  place-items: center;
  background-color: #f5f5f5;
  width: 100%;
  overflow: scroll;
  border-radius: 15px;
  overflow-x: hidden;
  overflow-y: visible;
}
.sidebar-detail::-webkit-scrollbar-thumb {
  background-color: #833471 !important;
}
/* Course Card */
.course-card-alt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  border: 0.25px solid #6f1e51;
  margin: 10px 0px;
}
.course-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #833471;
  padding: 5px 10px;
}
.course-duration {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.46);
  padding: 5px 10px;
}
.learn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: auto;
  width: 100%;
  height: fit-content;
}
.tickImg {
  display: flex;
  margin: 5px;
}
.learn-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.46);
  width: 100%;
  margin: 10px;
}
.learn-section-alt {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  width: 100%;
}
.learning-card {
  width: 98%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: center;
  background-color: #f6f7ff;
  border-radius: 15px;
  padding: 5px;
  height: 520px;
}
@media only screen and (max-width: 600px) {
  .learning-card {
    display: flex;
    height: fit-content!important;
    flex-direction: column;
    width: 100% !important;
    margin: 10px auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .course-name {
    font-size: 14px;
  }
  .learn-section {
    margin: 10px auto !important;
  }
  .learn-wrap {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row;
    margin: 10px;
    width: 100% !important;
    height: fit-content;
  }
  .learn-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.46);
    margin: 10px;
  }
  .sidebarHeaderLeft {
    font-size: 28px;
  }
  .card-benefit{
    font-size: 18px;
  }
}
/* Choose us */
.choose-us {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 40px auto;
}
.chooseus-banner {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto auto 50px;
}
.banner-image {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 497px;
  height: 609px;
  border-radius: 10px;
  background-color: #6f1e51;
  object-fit: contain;
  align-items: center;
  justify-content: center;
}
.banner-headimg{
  width: 350px;
  height: 350px;
}
.banner-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
}
.banner-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
}
.chooseus-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: auto;
}
.content-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #833471;
}
@media only screen and (max-width: 600px) {
  .choose-us {
    margin: 10px auto;
    height: fit-content!important;
    align-items: center;
    justify-content: center;
  }
  .chooseus-banner {
    display: none;
  }
  .chooseus-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-head {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #833471;
  }
}
/* Question Section*/
.question-section {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  margin: 40px auto;
}
.question-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.question-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 96%;
  height: fit-content;
  margin: 10px;
  background-color: #f6f7ff;
  border-radius: 11px;
}
.card-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 20px;
}
.question {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #833471;
  width: 80%;
  cursor: pointer;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.answer {
  display: none;
  align-items: flex-start;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.46);
  text-align: justify;
  width: 90%;
}
@media only screen and (max-width: 600px) {
  .question-section {
    margin: 10px auto;
  }
  .question {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #833471;
  }
  .answer {
    display: none;
    align-items: flex-start;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.46);
    text-align: justify;
    width: 90%;
  }
  .section-heading {
    font-size: 30px;
    margin: 10px !important;
  }
  .card-top {
    padding: 10px;
  }
}
/* Batches Card */
.batches-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f6f7ff;
  border: 0.25px solid #6f1e51;
  border-radius: 15px;
  margin-top: 30px;
}
.batchcard-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 10px;
  font-size: 24px;
  line-height: 36px;
}
.available {
  font-family: "Poppins";
  font-weight: 700;
  width: 66%;
  text-align: center;
  color: #833471;
}
.nextbatch {
  font-family: "Poppins";
  font-weight: 700;
  text-align: center;
  width: 33%;
  color: #b55851;
}
.batchcard-foot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  padding: 10px;
}
.date {
  width:33%;
  height: 60px;
  border-radius: 11px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #833471;
  background-color: #ffffff;
}
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
}
.wrap {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  color: #833471;
}
.type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.46);
}
@media only screen and (max-width: 600px) {
  .batches-card {
    margin: 10px 0px 20px 0px !important;
  }
  .batchcard-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 10px;
  }
  .available {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #833471;
  }
  .nextbatch {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #b55851;
    width: 100%;
  }
  .batchcard-foot {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 10px;
  }
  .date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #833471;
    margin: 10px 0px;
  }
  .timer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
  .wrap {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .number {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    color: #833471;
  }
  .type {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.46);
  }
}
