.footer {
  width: 100%;
  height: 400px;
  background-color: #6f1e51;
  position: relative;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.head-alt{
    margin-left:-20px;
    max-width: 220px;
    margin: auto;
}

.terms-padding{
    padding: 0px 20px;
}
.footer-section{
    display: flex;
    flex-direction: column;
    width: 18%;
    color: #ffffff;
    margin: 30px;
    justify-content: center;
}
.company-motto{
    font-size: 14px;
    padding: 0px 0px 0px 20px;
    line-height: 25px;
}
.foot-social{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-logo{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    height: 25px;
    width: 25px;
}
.list-head{
    font-weight: 900;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 20px;
}
.list-item{
    margin: 10px 0px;
    font-weight: 100;
    opacity: 0.8;
}
.foot-list{
    display: flex;
    flex-direction: column;
}
.label-subs{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.subscribe-inp{
    height:100%;
    background-color: transparent;
    border: 1px solid #fafafa;
    outline: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin: 0px;
    padding: 0px 10px;
    color: #fff;
}
.subscribe-inp::placeholder{
    color: #fff;
}
.subscribe-btn{
    height: 42px;
    width: 40px;
    background-color: #64A5CA;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #fafafa;
    outline: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.submit-ico{
    width: 15px;
    height: 15px;
}
.input-bind{
    height: 40px;
    
    display: flex;
}
@media only screen and (max-width: 600px) {
    .foot-logo{
        margin: auto;
    }
    .head-alt{
        color: #8334718e;
    }
}