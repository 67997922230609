@import url("https://fonts.googleapis.com/css?family=Poppins");
.event-section-2 {
  display: flex;
  width: 100%;
  background-color: black;
}
.event-banner {
  display: flex;
  flex-direction: column;
  height: 310px;
  width: 96%;
  margin: auto;
  border-radius: 15px;
  background-color: #6f1651;
  background-image: url("../../assets/girl-banner-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
}
.event-container {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin: 40px auto;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  position: relative;
  margin: 40px auto;
}
.event-head {
  font-family: "Poppins";
  font-weight: 600;
  color: #6f1651;
  line-height: 72px;
  font-size: 40px;
}
.event-subhead {
  color: "rgba(0, 0, 0, 0.4)" !important;
  font-size: "20px" !important;
  line-height: "30px" !important;
  font-weight: "400" !important;
}
.event-listing {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.pastevent-listing {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  place-items: center;
  margin: 20px auto;
}
@media only screen and (max-width: 600px) {
  .event-banner {
    width: 100%;
    font-size: 30px;
    line-height: 30px;
    margin: 20px auto;
    background-image: url("../../assets/wave-top.png");
    object-fit: contain!important;
  }
  .event-head {
    margin: 10px;
    font-size: 30px;
  }
  .event-subhead br {
    display: none;
  }
  .event-subhead {
    font-size: 20px !important;
    text-align: justify !important;
    line-height: 20px;
    font-weight: 700;
    width: 80%;
    display: none;
  }
  .event-listing {
    margin: 10px auto;
  }
  .banner-blur {
    width: 90%;
    height: 200px;
  }
  .banner-blur-s {
    height: 110px;
  }
}
/* Upcoming Event Card*/
.upcoming-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 99%;
  background-color: #ffffff;
  box-shadow: 10px 10px 30px 10px rgba(242, 242, 242, 0.69);
  border-radius: 11px;
  margin: 10px;
  height: 100%;
}
.event-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px 10px 10px;
  height: 100%;
}
.event-img {
  background-repeat: no-repeat;
  height: 100%;
  width: 380px;
  border-radius: 8px;
  margin: auto;
  object-fit: cover;
}
.event-body {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.event-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #b55851;
}
.event-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  color: #6f1651;
}
.event-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
  margin: 5px 0px;
}
.event-detail {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.event-duration,
.event-starting,
.event-trial {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.46);
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-creator {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.34);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.creator-avatar {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px whitesmoke solid;
}
.event-register {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.registerBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 46px;
  background-color: #6f1651;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.card-icon {
  margin: 2px;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 600px) {
  .upcoming-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .event-img {
    width: 95%;
    margin: 10px auto;
  }
  .event-body {
    width: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px auto;
  }
  .event-title {
    font-size: 18px;
    margin: 0px;
    text-align: justify;
    line-height: 25px;
  }
  .event-subtitle {
    line-height: 25px;
    text-align: justify;
  }
  .event-detail {
    flex-direction: column;
  }
  .event-register {
    margin: 20px 0px !important;
  }
  .event-duration,
  .event-starting,
  .event-trial {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 10px;
    margin: 10px 0px;
  }
  .event-creator {
    line-height: 10px;
    margin: 10px 0px;
  }
}
/* Past Event Card*/
.past-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 95%;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 10px;
}
.watchBtn {
  width: 180px;
  height: 40px;
  border: 1px solid #6f1651;
  border-radius: 11px;
  color: #243a73;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pasteventImg {
  background-repeat: no-repeat;
  height: 70%;
  width: 250px;
  border-radius: 10px;
  margin: auto;
  object-fit: cover;
}
@media only screen and (max-width: 600px) {
  .pastevent-listing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }
  .past-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pasteventImg {
    width: 95%;
    margin: 10px 0px 0px 0px;
  }
  .watchBtn{
    width: 100%;
  }
  .event-register{
    width: 100%;
  }
}
