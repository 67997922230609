/* Banner Section */
.banner-section {
  display: flex;
  flex-direction: row;
  height: 550px;
  width: 90%;
  margin: auto;
  border-radius: 15px;
  background: url("../../assets/girl-banner-bg.svg");
  background-color: #6f1651;
  /* background-size: cover; */
  background-repeat: no-repeat;
  align-items: center;
  color: #ffffff;
  font-weight: 900;
  font-size: 20px;
  line-height: 38px;
}
.banner-frame {
  height: 580px;
  width: 50%;
  margin: auto;
  display: flex;
}
.banner-picture {
  width: 100%;
  height: 100%;
  margin: auto;
}
.banner-info {
  max-height: 500px;
  width: 40%;
  margin: auto;
}
.banner-heading {
  line-height: 55px;
}
.banner-subhead {
  font-size: 15px;
  line-height: 22px;
  font-weight: 100;
  letter-spacing: 2px;
  margin-bottom: 30px;
}
.arrowR {
  height: 10px;
  margin-left: 10px;
}
.banner-button {
  display: flex;
  height: 45px;
  width: 200px;
  padding: 6px;
  font-size: 12px;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 0px none transparent;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase;
  color: #243a73;
  box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.social-align {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.social-logo-2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 60px;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 600px) {
  .banner-section {
    flex-direction: column;
    width: 100%;
    background: url("../../assets/wave-top.png");
    background-color: #6f1651;
    background-size: contain;
    height:fit-content;
    background-repeat: no-repeat;
  }
  .banner-heading {
    font-size: 20px !important;
    line-height: normal;
  }
  .banner-heading-text {
    margin: 20px;
    line-height: 35px;
    font-size: 24px !important;
  }
  .banner-subhead {
    margin: 20px;
  }
  .banner-button {
    margin: auto;
  }
  .banner-info {
    width: 100%;
  }
  .banner-frame {
    width: 100%;
    height: 520px;
  }
  .social-align {
    align-items: center !important;
    justify-content: center !important;
  }
}
/* ///////////////////////////////////////////////////////////////////////////////// */

/* Learn Section */
.learn-section {
  text-align: center;
  margin: 50px auto;
  border-radius: 20px;
  /* background-color: #6f1651; */
  background-image: url('../../assets/samp-wave.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  width: 90%;
  display: flex;
  height: fit-content;
}
.learn-info {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 50%;
  margin: 40px 30px auto 60px;
}
.learn-info-title {
  font-size: 40px;
  color: #6f1651;
}
.learn-info-subtitle {
  font-size: 18px;
  color: #0000004d;
}
.learn-control {
  width: 100%;
}
.learn-control-btn {
  height: 40px;
  width: 55px;
  border-radius: 8px;
  margin-right: 20px;
  border: 0px none transparent;
  background-color: #ffffff;
  box-shadow: 6px 6px 60px 1px #dcdcdc;
  cursor: pointer;
}
.learn-control-btn:hover {
  box-shadow: 6px 6px 30px 1px #dcdcdc;
}
.btn-ico {
  height: 10px;
}
.learn-visual {
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.learn-cards {
  display: flex;
  flex-direction: row;
  width: 60%;
  height: 100%;
  margin: 0px auto 30px auto;
}
.card-hide{
  display: none!important;
  visibility: hidden!important;
}
.learn-card-big {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 280px;
  background-color: #ffffff;
  margin: auto;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 6px 6px 50px 1px #00000082;
}
.learn-card-small {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 230px;
  background-color: #ffffff;
  margin: 60px auto auto 40px;
  border-radius: 11px;
  padding: 20px;
  box-shadow: 6px 6px 30px 1px #dcdcdc;
}
.learn-card-pic {
  height: 200px;
  width: 200px;
  margin: auto;
}
.learn-card-title {
  font-size: 18px;
  color: #6f1651;
  font-weight: 600;
}
.learn-card-desc {
  color: #8c8c8c;
  font-size: 12px;
}
.learn-slider {
  display: flex;
  flex-direction: row;
  margin: 20px auto 40px auto;
  height: 8px;
  width: fit-content;
}
.selector-blank {
  height: 100%;
  width: 40px;
  background-color: #e9d5ca;
  margin: auto 5px;
  border-radius: 10px;
}
.selector-filled {
  background-color: #6f1651 !important;
}
@media only screen and (max-width: 600px) {
  .learn-section{
    width: 100%;
    flex-direction: column;
    margin: 0px;
    height: fit-content;
    background-image: url('../../assets/wave.png');
  }
  .learn-info{
    width: auto;
    margin: 20px;
  }
  .learn-info-title{
    margin: 10px;
    font-size: 30px;
  }
  .learn-info-subtitle{
    font-size: 16px;
    margin: 10px;
  }
  .learn-visual{
    width: 100%;
  }
  .learn-cards{
    flex-direction: column;
  }
  .learn-card-big , .learn-card-small{
    margin: 10px auto;
    width: fit-content;
  }
}
/* /////////////////////////////////////////////////////////////////////// */

/* Partner Section */
.partner-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: center;
}
.partner-label {
  color: #6f1651;
  font-weight: 900;
}
.partner-card {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  min-width: 250px;
  margin: 30px 20px;
  background-color: #f5f5f5;
  border-radius: 11px;
}
.partner-card:hover {
  box-shadow: 20px 20px 30px 10px #f2f2f2;
  background-color: #ffffff;
}
.partner-card-slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}
.partner-card-slider::-webkit-scrollbar {
  visibility: hidden;
}
@media only screen and (max-width: 600px) {
  .partner-card{
    min-height: 100px;
    min-width: 200px;
    margin: 10px;
    box-shadow: 5px 5px 10px 5px #f2f2f2;
    background-color: #ffffff;
  }
  .partner-section{
    margin: 0px 0px 0px 40px;
  }
}
/* ////////////////////////////////////////////////////////////////////////////// */

/* Graduates Section */
.graduates-section {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 650px;
  background-color: #f5f5f5;
  border-radius: 11px;
  margin: 40px auto;
}
.graduate-heading {
  color: #243a73;
  margin: 40px auto auto auto;
}
.graduate-card-holder {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.graduate-card-base {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 40%;
  height: 80%;
  background-image: url("../../assets/Vector.png");
  background-repeat: no-repeat;
  object-fit: cover;
}
.card-base {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.card-dots {
  width: 100%;
  display: flex;
}
.self-align-end {
  margin-left: auto;
  margin-right: -50px;
  vertical-align: middle;
}
.extra-setting {
  margin-top: -80px!important;
  margin-right: -60px!important;
  border: 10px white solid!important;
}
.graduate-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 11px;
  height: fit-content;
  width: 550px;
  z-index: 2;
  position: absolute;
  justify-content: center;
  align-items: center;
  box-shadow: 6px 6px 30px 1px rgba(0, 0, 0, 0.09);
}
.graduate-slider {
  display: flex;
  flex-direction: row;
  margin: 20px auto 40px auto;
  height: 8px;
  width: fit-content;
  position: absolute;
  z-index: 2;
  top: 110%;
}
.graduate-name {
  color: #243a73;
  font-weight: 900;
  margin-top: 10px;
}
.graduate-testimonial {
  text-align: center;
  color: #8c8c8c;
  margin-top: 0px;
}
.graduate-picture {
  height: 80px;
  width: 80px;
  margin-top: 30px;
  border-radius: 50%;
  border: 10px whitesmoke solid ;
}
.plus{
  margin-top: 0px!important;
  object-fit: cover;
  border: 10px white solid!important;
}
.graduate-company {
  margin-bottom: 30px;
}
.decorates {
  height: 60px;
  width: 60px;
  vertical-align: middle;
}
@media only screen and (max-width: 600px) {
  .graduates-section{
    width: 100%;
    margin: 30px 0px 90px 0px;
    height: fit-content;
  }
  .graduate-heading{
    margin: 20px auto;
  }
  .graduate-card-base{
    display: none;
    visibility: hidden;
  }
  .graduate-card{
    width: 94%;
    position: relative;
    height: auto;
  }
  .graduate-testimonial{
    font-size: 14px;
    margin: 0px 10px;
  }
  .graduate-picture{
    border-radius: 50%;
    border: 10px whitesmoke solid ;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////// */

/* Bottom Banner Section */
.banner-button-alt {
  display: flex;
  height: 55px;
  width: 280px;
  padding: 6px;
  font-size: 14px;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  background-color: #e9d5ca;
  border: 0px none transparent;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase;
  color: #243a73;
  box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.12);
  margin: auto;
  cursor: pointer;
}
.button-patch {
  background-color: #cdf0ea !important;
  color: #6f1651 !important;
  height: 60px;
  border-radius: 15px;
  letter-spacing: 2px;
}
.arrowW {
  width: 45px;
  height: 12px;
}
.banner-bottom {
  background-color: #6f1651;
  width: 96%;
  height: 280px;
  margin: auto auto 100px auto;
  border-radius: 11px;
}
.banner-bottom-graphic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../assets/girl-banner-bg.svg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.banner-bottom-text {
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  line-height: 50px;
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .partner-label{
    font-size: 25px;
  }
  .partner-section{
    margin: auto 0px;
  }
  .banner-bottom-text{
    font-size: 20px;
    line-height: normal;
  }
  .banner-bottom{
    margin: 40px auto;
    height: 250px;
  }
  .banner-bottom-graphic{
    background-repeat: no-repeat;
    border-radius: 11px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////// */

/* Detail Section */
.detail-section {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.figure-section {
  width: 96%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: auto;
  padding-bottom: 20px;
}
.figure-visuals {
  display: flex;
  align-items: center;
  justify-content: center;
}
.figure-graphic {
  width: 450px;
  height: 400px;
}
.figure-area {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}
.figure-heading {
  color: #6f1651;
  margin: 40px auto 20px auto;
}
.figure-subheading {
  opacity: 0.6;
  color: #000;
  margin: 10px auto 50px auto;
}
.figure-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 200px;
  border-radius: 11px;
  background-color: #6f1651;
  cursor: pointer;
  color: #fff;
  box-shadow: 10px 5px 50px 10px #00000055;
  margin: auto;
}
.figure-number {
  margin: 5px 0px !important;
}
.figure-lines-s {
  height: 60%;
  width: 150px;
  vertical-align: middle;
  align-self: flex-end;
}
.figure-lines-b {
  height: 120px;
  width: 230px;
  vertical-align: middle;
}
.figure-base {
  display: flex;
  width: 70%;
}
@media only screen and (max-width: 600px) {
  .figure-section{
    height: fit-content;
    margin: 80px auto auto auto;
  }
  .figure-heading{
    font-size: 25px;
  }
  .figure-subheading{
    margin: 20px auto;
    font-size: 14px;
    text-align: center;
  }
  .figure-graphic{
    width: 330px;
    height: 315px;
  }
  .figure-visuals{
    flex-direction: column;
  }
  .figure-area{
    width: 100%;
    margin: 20px auto 20px auto;
    flex-direction: column;
  }
  .figure-base{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .self-align-end{
    margin: 0px;
  }
  .figure-lines-b,.figure-lines-s{
    display: none;
  }
  .figure-card{
    margin: 10px auto;
  }
}
/* /////////////////////////////////////////////////////////////////////// */

/* Explore Section */
.explore-section {
  width: 96%;
  height: fit-content;
  border-radius: 15px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}
.course-section,
.event-section {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: fit-content;
  margin: 30px auto;
}
.course-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.course-heading {
  color: #6f1651;
}
.course-view-btn {
  color: #6f1651;
  cursor: pointer;
}
.course-list{
  width: 100%;
  display: flex;
  margin: auto;
  overflow: scroll;
  overflow-y: hidden;
}
.course-card {
  height: fit-content;
  margin: 15px;
  background-color: #fff;
  width: 300px;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 10px 10px 30px 10px #f2f2f2;
}
.course-image {
  height: 100%;
  width: 100%;
  margin: auto 10px auto 0px;
}
.course-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 10px;
}
.course-title,
.event-title {
  font-size: 18px;
  color: #6f1651;
  font-weight: 900;
  margin: 5px 0px;
}
.course-description {
  opacity: 0.5;
  text-align: justify;
  font-size: 15px;
  line-height: 25px;
  margin: 5px 0px;
}
.course-length {
  color: rgba(0, 0, 0, 0.405);
}
.course-ico {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin: 10px;
  opacity: 0.9 !important;
}
.course-tags {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.course-tag:nth-child(odd) {
  color: rgba(36, 58, 115, 0.58);
  background-color: #e9d5ca;
  border-radius: 20px;
  padding: 8px 10px;
  width: 120px;
  height: 30px;
  letter-spacing: 0.5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-tag:nth-child(even) {
  color: rgba(36, 58, 115, 0.58);
  border-radius: 20px;
  padding: 8px 10px;
  width: 120px;
  height: 30px;
  letter-spacing: 0.5px;
  font-size: 14px;
  margin: auto 10px;
  background-color: #cdf0ea !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-rates{
  display: flex;
  height: 100%;
  align-items: center;
}
.course-price {
  color: #6f1651;
  font-size: 25px;
  font-weight: bolder;
  margin: auto 10px;
}
.course-discount{
  color: grey;
  font-size: 18px;
  margin: 10px 10px;
}
.event-list {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}
.event-card {
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: auto 15px 30px 15px;
  height: fit-content;
  width: 335px;
  border-radius: 11px;
  box-shadow: 10px 10px 30px 10px #f2f2f2;
}
.event-foot{
  display: flex;
  justify-content: space-between;
}
.event-info {
  margin: 10px;
  height: 100%;
}
.event-logo {
  width: 100%;
  height: 85%;
  margin: auto;
}
.event-time {
  color: #ffa40b;
  text-transform: uppercase;
  font-weight: 900;
}
.event-title {
  font-size: 20px !important;
  line-height: 25px;
}
.event-host {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10px;
}
.event-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 10px 0px;
  background-color: #6f1651;
  color: #fff;
  width: 180px;
  height: 40px;
}
.event-slider {
  position: relative !important;
  margin: 40px auto 20px auto !important;
  z-index: 0;
}
@media only screen and (max-width: 600px) {
  .explore-section{
    width: 100%;
    height: fit-content;
    margin: 20px auto;
  }
  .event-list{
    grid-template-columns: none;
    grid-template-rows: auto;
  }
  .course-list a{
    margin: 10px 10px;
  }
  .course-card{
    width: 100%;
    height: fit-content;
    flex-direction: column;
    padding: 0px;
    border: 0.25px solid #6f1651bf;
    margin: 0px;
  }
  .course-heading{
    font-size: 25px;
  }
  .course-image{
    margin: 10px auto;
    width: 90%;
  }
  .course-description{
    font-size: 14px;
    width: 97%;
  }
  .course-title{
    font-size: 18px;
  }
  .course-price{
    margin: 10px;
  }
  .course-info{
    width: fit-content;
  }
  .event-card{
    flex-direction: column;
    width: 90%;
    margin: 10px;
    border: 0.25px solid #6f1651;
  }
  .course-tags{
    height: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
  }
  .course-tag:nth-child(even){
    line-height: 50px;
    margin: 5px;
  }
  .event-logo{
    margin: 10px auto;
    width: 100%;
  }
  .event-btn{
    margin: 15px 0px;
  }
}