@import url("https://fonts.googleapis.com/css?family=Poppins");
.Previous-Bootcamp {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  width: 90%;
  height: 100%;
  border-radius: 15px;
  margin: auto;
}
.previousbootcamp-section {
  display: flex;
  width: 100%;
}
.previousbootcamp-banner {
  display: flex;
  flex-direction: row;
  height: 400px;
  width: 100%;
  margin: auto;
  border-radius: 15px;
  background-image: url("../../assets/girl-banner-bg.svg");
  object-fit: cover;
  background-color: #6f1e51;
  background-repeat: no-repeat;
}
/* ------------------ */
/* Container Section */
.previousbootcamp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0px auto;
  background-color: #f5f5f5;
  position: relative;
}
.banner-right {
  width: 30%;
  display: flex;
  margin: 0px 10px;
}
.banner-left {
  width: 70%;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 10px;
}
.dev-img {
  width: 100%;
  height: 100%;
}
.banner-heading-alt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #b55851;
}
.banner-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #fff;
}
.banner-foot {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f5f5f5;
  margin-top: 5px;
}

.bootcamp-wrapper-alt {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}
.wrapper-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  align-self: flex-start;
}
@media only screen and (max-width: 600px) {
  .Previous-Bootcamp {
    padding: 0px !important;
  }
  .previousbootcamp-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    height: 250px;
    padding: 0px !important;
    background-image: url("../../assets/wave-top.png");
  }
  .banner-right {
    display: none;
    visibility: hidden;
  }
  .banner-left {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
  }
  .banner-heading {
    font-size: 20px;
    line-height: 30px;
    margin: 5px 0px;
  }
  .banner-sub {
    font-size: 25px;
    line-height: 30px;
    width: 100%;
    margin: 5px 0px;
  }
  .bootcamp-wrapper-alt {
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }
  .wrapper-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    margin: 10px auto !important;
  }
  .wrapper-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    margin: 20px auto 0px auto !important;
  }
}
/* Instructor Card */
.instructor-card {
  background-color: #ffffff;
  border: 0.25px solid #6f1e51;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 10px;
  margin-top: 10px;
}
.img-wrapper {
  display: flex;
  align-items: center;
  margin: 10px;
  /* width: 50%; */
  height: 100%;
}
.instructor-img {
  width: 330px;
  height: 50%;
}
.instructor-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  width: 50%;
  height: 100%;
}
.card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #b55851;
}
.instructor-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #6f1e51;
}
.instructor-bio {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.31);
  margin-top: 10px;
}
.program-schedule {
  width: 100%;
  background-color: #ffffff;
  border: 0.25px solid #6f1e51;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 20px;
}
.schedule-listing {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  place-items: center;
  height: 605px;
  overflow: scroll;
  overflow-x: hidden;
}
.schedule-listing::-webkit-scrollbar-thumb {
  background-color: #833471 !important;
}
.card-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  color: #6f1e51;
  margin: 10px;
}
@media only screen and (max-width: 600px) {
  .instructor-card {
    width: 98% !important;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
  }
  .img-wrapper {
    width: 100%;
  }
  .instructor-details {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 90%;
    height: 100%;
  }
  .instructor-img {
    width: 90% !important;
    margin: auto;
  }
  .card-title {
    line-height: 30px;
  }
  .instructor-name {
    font-size: 30px;
    line-height: 30px;
    color: #243a73;
    margin: 10px 0px;
  }
  .instructor-bio {
    font-size: 20px;
    line-height: 20px;
    margin-top: 10px;
  }
}
/* ------------- */
/* Schedule Card*/
.schedule-card {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  border-radius: 15px;
}
.header-section {
  display: flex;
  flex-direction: row;
  height: 200px;
  width: 100%;
  margin: 10px auto;
  background-image: url("../../assets/videoback.png");
  object-fit: contain;
  align-items: center;
  justify-content: center;
}
.play-img {
  width: 100%;
  height: 100%;
}
.detail-section-alt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 10px;
}
.video-detail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #6f1e51;
}
.video-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #833471;
}
@media only screen and (max-width: 600px) {
  .program-schedule {
    width: 100%;
    margin: 10px auto;
    padding: 0px !important;
    border: 0.25px solid #6f1e51;
    overflow: hidden;
  }
  .schedule-listing {
    display: flex;
    flex-direction: column;
  }
}
/* upcoming events */
.upcomingEvent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.events-listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto 70px auto;
}
.content-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #243a73;
}
.content-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.34);
}
.calendarImg {
  width: 61px;
  height: 61px;
}
.vertical-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #fff !important;
}
.vertical-details-listing {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: #ffffff;
  border-radius: 15px;
}
.vertical-header{
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.sidebar-detail-alt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  height: fit-content;
}
.sidebar-detail::-webkit-scrollbar,
.schedule-listing::-webkit-scrollbar {
  background-color: #ffffff;
  width: 5px;
}
.detail-heading {
  width: 100%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: #6f1e51;
}
.details-listing {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 15px;
  margin-left: 10px;
}
.details-listing-pt {
  width: 95%;
  height: 252px;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 10px;
}
@media only screen and (max-width: 600px) {
  .sidebar {
    margin: 10px auto !important;
    width: 98%;
    padding: 0px !important;
  }
  .sidebar-banner {
    display: flex;
    flex-direction: column;
    height: 351px;
    width: 95% !important;
    margin: auto;
    border-radius: 10px;
    background-image: url("../../assets/sidebar-banner.png");
    object-fit: contain;
    align-items: center;
    justify-content: center;
  }
}
/* Details */
.details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  margin: 20px;
  background-color: #f6f7ff;
  border-radius: 11px;
}
.details-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 5px;
}
.detail-img {
  width: 30px;
  height: 30px;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.34);
}
.subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #243a73;
}
.sidebar-banner {
  display: flex;
  flex-direction: column;
  height: 351px;
  width: 350px;
  margin: auto;
  border-radius: 10px;
  background-image: url("../../assets/sidebar-banner.png");
  object-fit: contain;
  align-items: center;
  justify-content: center;
}
.child-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
}
.child {
  width: 241px;
  height: 243px;
}
.banner-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.shop-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.shopNowBtn {
  width: 213px;
  height: 43px;
  background-color: #eddfd7;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #243a73;
  outline: none;
}
.sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0px 20px 25px;
}
.footer-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #243a73;
}
.footer-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.31);
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  .details {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100px;
    margin: 18px;
    background-color: #f6f7ff;
    border-radius: 11px;
  }
}
