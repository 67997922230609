@import url('https://fonts.googleapis.com/css?family=Poppins');
.allcourses-section{
    display: flex;
    width: 100%;
    /* background-color: #F5F5F5 */
}
.allcourses-banner{
    display: flex;
    flex-direction: column;
    height: 310px;
    width: 96%;
    margin: auto;
    border-radius: 15px;
    background-image: url('../../assets/girl-banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #6f1651;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 22px;
    line-height: 72px;
    /* margin: 40px auto; */
}
.card-link{
    margin: 0px 0px!important;
}
.event-container{
    display: flex;
    flex-direction: column;
    width: 96%;
    margin: 40px auto;
    background-color: #F5F5F5;
    border-radius: 20px; 
    padding-bottom: 10px;
    position: relative;
    margin: 40px auto;
}
.allcourses-btn{
    width: 406px;
    height: 80px;
    background: rgba(217, 217, 217, 0.2);
    backdrop-filter: blur(200px);
    border-radius: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 600px) {
    .allcourses-banner{
        width: 99%;
        margin: 10px auto;
        padding: 0px;
        background-image: url("../../assets/wave-top.png");
    }
    .allcourses-btn{
        width: 306px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        padding: 0px;
    }
    .event-container{
        padding: 0px;
    }
    .card-link{
        width: 100%;
    }
    .registerBtn{
        width: 100%;
    }
}