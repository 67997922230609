.ebf{
    width: 40%!important;
    height: 550px!important;
    margin-right: 0!important;
}
.ep{
    overflow: hidden;
    width: 90%!important;
    margin-right: 0px!important;
}
.ep-alt{
    width: 70%!important;
    height: 100%!important;
}
.ebs{
    overflow: hidden;
}
.ebht{
    color: #e67e22;
    font-weight: 900!important;
    font-size: 50px!important;
    margin: 0!important;
    -webkit-text-stroke: 1px #f1c40f!important;
}
.ebi{
    margin: 0px 0px 0px 100px!important;
    width: 50%;
}
.esn{
    color: #e67e22;
    font-weight: 900!important;
    font-size: 18px;
    -webkit-text-stroke: 0.1px #f1c40f!important;   
}
.ebsh{
    font-size: 18px;
    line-height: 25px;
}

@media only screen and (max-width: 600px) {
    .banner-heading{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;
    }
    .banner-subhead{
        margin: 15px auto!important;
    }
    .ebi{
        width: 90%!important;
        margin: auto!important;
    }
    .ul{
        margin: 0px!important;
        padding: 0px!important;
    }
    .ebf{
        width: 100%!important;
    }
    .banner-picture{
        height: fit-content!important;
    }
    .ep{
        width: 100%!important;
        margin: auto 0px 0px auto!important;
    }
}