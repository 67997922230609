.store-container {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin: 40px auto;
  background-color: #f8f8f8;
  border-radius: 15px;
  color: #6f1651;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  position: relative;
}
.store-listing {
  width: 96%;
  border-radius: 15px;
  height: 100%;
  background-color: #ffffff;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.store-head {
  margin-left: 30px;
}
.banner-section-store {
  display: flex;
  flex-direction: column;
  height: 310px;
  width: 96%;
  margin: auto;
  border-radius: 15px;
  background-image: url("../../assets/girl-banner-bg.svg");
  background-color: #6f1651;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 900;
  font-size: 25px;
  line-height: 40px;
}
.banner-blur {
  background: rgba(217, 217, 217, 0.4);
  backdrop-filter: blur(150px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 50%;
}
/* Store Item */
.store-card {
  width: 280px;
  height: 450px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 15px;
  margin: 25px auto;
}
.store-card:hover {
  box-shadow: 5px 10px 18px #888888;
  cursor: pointer;
}
.item-image {
  /* background: url('../../assets/product.png'); */
  background-repeat: no-repeat;
  height: 240px;
  width: 240px;
  border-radius: 10px;
  margin: auto;
  object-fit: cover;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.item-rating,
.item-like {
  margin: 10px;
}
.purchase-button {
  width: 240px;
  height: 36px;
  color: #fff;
  background-color: #6f1651;
  margin: auto;
  font-size: 18px;
  outline: none;
  border: 0px none transparent;
  border-radius: 5px;
  cursor: pointer;
}
.item-price {
  display: flex;
  font-size: 18px;
  margin: auto 20px;
}
.item-heading {
  margin: auto 20px;
  font-size: 20px;
  color: #000;
  font-weight: 900;
}
.item-category {
  margin: auto 20px;
  color: #737576;
  font-size: 15px;
}
.item-discount {
  color: #bdbdbd;
}
.item-sale-price {
  font-size: 20px;
  font-weight: 900;
  margin: auto 10px;
}
@media only screen and (max-width: 600px) {
  .store-listing {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
  }
  .banner-section-store{
    background-image: url("../../assets/wave-top.png");
  }
  .store-container {
    width: 100%;
    padding: 0px;
  }
  .store-card {
    height: 300px;
    width: 170px;
    padding: 8px;
    margin: 10px auto;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
  }
  .purchase-button {
    width: 100%;
  }
  .item-image {
    height: 150px;
    width: 100%;
  }
  .item-sale-price {
    margin: 0px;
  }
  .store-head {
    font-size: 30px;
    margin-left: 10px;
  }
}
