@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
 /*Light :#833471, 
 Dark: #6f1e51 */
body {
  padding: 0px;
  margin: 0px;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  background-color: #833471;
}
.partner-card-slider::-webkit-scrollbar,
.card-section::-webkit-scrollbar,
.techcard-section::-webkit-scrollbar {
  background-color: transparent;
}
.partner-card-slider::-webkit-scrollbar-thumb,
.card-section::-webkit-scrollbar-thumb,
.techcard-section::-webkit-scrollbar-thumb {
  background-color: transparent;
}

a {
  text-decoration: none;
  margin: auto;
}
.link-ov {
  margin: 0px;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  body {
    padding: 0px!important;
  }
}
@media (-webkit-device-pixel-ratio: 1.5) {
  * {
    zoom: 98%;
  }
}
@media (-webkit-device-pixel-ratio: 1.0) {
  * {
    zoom: 98%;
  }
}