.community-header{
  display: flex;
  height: 40px;
  width: 100%;
  position: relative;
  outline: none;
  top: 0px;
  color: rgb(89,89,89);
  z-index: 999;
  background-color: rgb(217, 239, 189);
  justify-content: center;
  align-items: center;
}
.header {
  display: flex;
  width: 100%;
  height: 90px;
  top: 0px;
  position: sticky;
  z-index: 999;
  align-items: center;
  overflow: hidden;
  margin-bottom: 60px;
  background-color: #fff;
  box-shadow: 0.5px 0.2px 0.2px 0.25px rgba(0, 0, 0, 0.1);
}
.header-ham {
  display: none;
}
.header-logo {
  object-fit: contain;
  height: 100%;
  width: 300px;
}
.header-L {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  right: 0px;
  position: absolute;
}
.header-R {
  height: 100%;
  width: fit-content;
  overflow: hidden;
}
.header-option {
  color: #833471;
  font-size: 18px;
  /* text-transform: uppercase; */
  font-weight: 600;
  letter-spacing: 1.5px;
  cursor: pointer;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
}
.selected {
  border-radius: 25px;
  border: 1px solid #6f1651;
}
.header-login {
  height: 40px;
  width: 120px;
  color: #fff;
  background-color: #833471;
  border-radius: 5px;
  text-transform: uppercase;
  border: 0px none transparent;
  font-weight: 600;
  letter-spacing: 1px;
}
.header-search {
  width: 20px;
  height: 20px;
}
.menu-blur {
  display: none;
}
@media only screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    height: fit-content;
    width: 100%;
    padding-bottom: 20px;
  }
  .footer-section {
    width: fit-content;
    display: flex;
  }
  .community-header{
    text-align: center;
    padding: 5px 0px;
  }
  .header {
    flex-direction: row;
    top:0px;
    position: sticky;
    width: 100%;
    display: flex;
    height: 70px;
    align-items: center;
  }
  .header-L {
    display: none;
    position: relative;
    width: 100%;
  }
  .company-motto {
    line-height: normal;
  }
  .header-menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 70%;
    height: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 9999;
  }
  .header-option {
    height: 50px;
    width: 100%;
    margin: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selected {
    background-color: #6f1651;
    color: white;
  }
  .menu-blur {
    display: none;
    background-color: rgba(0, 0, 0, 0.325);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }
  .header-ham {
    display: block;
    margin: auto 20px auto auto;
  }
  .ham-ico {
    height: 35px;
    width: 35px;
  }
  .header-logo {
    width: 180px;
  }
  .head-alt {
    margin: auto;
  }
}
